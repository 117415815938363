<template>
  <div>
    <router-link
        :to="{ name: TOURNAMENT.attributes.participant_type === 'TEAM' ? 'team.name' : 'another-profile', params: { id: winner.id }}"
        class="winner-card"
        target="_blank"
    >
      <div class="left-wrapper">
        <div class="place" :class="getPlace">
          <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.7542 14.4555C13.7374 14.4902 13.7374 14.5249 13.7542 14.5597L14.4613 15.4447C14.5286 15.5141 14.4613 15.6356 14.3771 15.6356H11.835V13.3449H14.3939C14.495 13.3449 14.5455 13.4664 14.4781 13.5358L13.7542 14.4555ZM0.505051 13.5358L1.21212 14.4208C1.22896 14.4555 1.22896 14.4902 1.21212 14.5249L0.488215 15.4447C0.43771 15.5141 0.50505 15.6356 0.589226 15.6356H3.13131V13.3449H0.606061C0.505051 13.3449 0.454545 13.449 0.505051 13.5358ZM11.9529 1.47505H15V3.8872C15 5.62256 13.6364 7.0282 11.9529 7.0282H11.5657C10.9933 8.32972 9.84848 9.31887 8.45118 9.63124V11.5922H9.54545C9.88216 11.5922 10.1515 11.8698 10.1515 12.2169V13.0499H11.0774V16H3.92256V13.0499H4.84848V12.2169C4.84848 11.8698 5.11785 11.5922 5.45455 11.5922H6.54882V9.61388C5.15152 9.30152 4.00673 8.32972 3.43434 7.01085H3.04714C1.36364 7.01085 0 5.60521 0 3.86985V1.47505H3.04714V0H11.9529V1.47505ZM3.04714 5.43167C3.04714 5.32755 3.0303 5.24078 3.0303 5.13666V3.07158H1.53199V3.8872C1.53199 4.73753 2.20539 5.43167 3.04714 5.43167ZM11.9529 3.07158V5.13666C11.9529 5.24078 11.9529 5.3449 11.936 5.43167H11.9529C12.7778 5.43167 13.4512 4.73753 13.4512 3.8872V3.07158H11.9529Z" fill="#C99307"/>
            <path d="M13.7542 14.4555C13.7374 14.4902 13.7374 14.5249 13.7542 14.5597L14.4613 15.4447C14.5286 15.5141 14.4613 15.6356 14.3771 15.6356H11.835V13.3449H14.3939C14.495 13.3449 14.5455 13.4664 14.4781 13.5358L13.7542 14.4555ZM0.505051 13.5358L1.21212 14.4208C1.22896 14.4555 1.22896 14.4902 1.21212 14.5249L0.488215 15.4447C0.43771 15.5141 0.50505 15.6356 0.589226 15.6356H3.13131V13.3449H0.606061C0.505051 13.3449 0.454545 13.449 0.505051 13.5358ZM11.9529 1.47505H15V3.8872C15 5.62256 13.6364 7.0282 11.9529 7.0282H11.5657C10.9933 8.32972 9.84848 9.31887 8.45118 9.63124V11.5922H9.54545C9.88216 11.5922 10.1515 11.8698 10.1515 12.2169V13.0499H11.0774V16H3.92256V13.0499H4.84848V12.2169C4.84848 11.8698 5.11785 11.5922 5.45455 11.5922H6.54882V9.61388C5.15152 9.30152 4.00673 8.32972 3.43434 7.01085H3.04714C1.36364 7.01085 0 5.60521 0 3.86985V1.47505H3.04714V0H11.9529V1.47505ZM3.04714 5.43167C3.04714 5.32755 3.0303 5.24078 3.0303 5.13666V3.07158H1.53199V3.8872C1.53199 4.73753 2.20539 5.43167 3.04714 5.43167ZM11.9529 3.07158V5.13666C11.9529 5.24078 11.9529 5.3449 11.936 5.43167H11.9529C12.7778 5.43167 13.4512 4.73753 13.4512 3.8872V3.07158H11.9529Z" fill="#E7E7E7"/>
            <path d="M13.7542 14.4555C13.7374 14.4902 13.7374 14.5249 13.7542 14.5597L14.4613 15.4447C14.5286 15.5141 14.4613 15.6356 14.3771 15.6356H11.835V13.3449H14.3939C14.495 13.3449 14.5455 13.4664 14.4781 13.5358L13.7542 14.4555ZM0.505051 13.5358L1.21212 14.4208C1.22896 14.4555 1.22896 14.4902 1.21212 14.5249L0.488215 15.4447C0.43771 15.5141 0.50505 15.6356 0.589226 15.6356H3.13131V13.3449H0.606061C0.505051 13.3449 0.454545 13.449 0.505051 13.5358ZM11.9529 1.47505H15V3.8872C15 5.62256 13.6364 7.0282 11.9529 7.0282H11.5657C10.9933 8.32972 9.84848 9.31887 8.45118 9.63124V11.5922H9.54545C9.88216 11.5922 10.1515 11.8698 10.1515 12.2169V13.0499H11.0774V16H3.92256V13.0499H4.84848V12.2169C4.84848 11.8698 5.11785 11.5922 5.45455 11.5922H6.54882V9.61388C5.15152 9.30152 4.00673 8.32972 3.43434 7.01085H3.04714C1.36364 7.01085 0 5.60521 0 3.86985V1.47505H3.04714V0H11.9529V1.47505ZM3.04714 5.43167C3.04714 5.32755 3.0303 5.24078 3.0303 5.13666V3.07158H1.53199V3.8872C1.53199 4.73753 2.20539 5.43167 3.04714 5.43167ZM11.9529 3.07158V5.13666C11.9529 5.24078 11.9529 5.3449 11.936 5.43167H11.9529C12.7778 5.43167 13.4512 4.73753 13.4512 3.8872V3.07158H11.9529Z" fill="#FFDE31"/>
          </svg>
        </div>
        <div class="team-label">
          <div class="logo">
            <img :src="winner.attributes.logo" alt="#">
          </div>
          <div class="name">{{ getWinnerName }}</div>
        </div>
      </div>
      <div class="prize">{{ getPrize }}</div>
    </router-link>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "winner-card",
  props: {
    winner: Object,
    place: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters([
        'TOURNAMENT'
    ]),
    getPlace() {
      return this.place === 0 ? 'gold' : this.place === 1 ? 'silver' : 'bronze'
    },
    getPrize() {
      return this.place === 0 ? this.TOURNAMENT.attributes.first_prize : this.place === 1 ? this.TOURNAMENT.attributes.second_prize : this.TOURNAMENT.attributes.third_prize
    },
    getWinnerName() {
      return this.TOURNAMENT.attributes.participant_type === 'TEAM' ? this.winner.attributes.name : this.winner.attributes.login
    }
  },
}
</script>

<style scoped>

</style>
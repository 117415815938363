<template>
  <div class="container">
    <div class="winners">
      <div class="title">
        <span class="s1">Место</span>
        <span class="s2">{{ TOURNAMENT.attributes.participant_type === 'USER' ? 'Игрок' : 'Команда' }}</span>
        <span class="s3">Призовые</span>
      </div>
      <div class="winners-wrapper">
        <winner-card
            v-for="(item, i) in WINNERS"
            :key="i"
            :place="i"
            :winner="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import WinnerCard from "@/components/tournaments/singleTournament/winners/winner-card";
export default {
  name: "tournamentWinners",
  components: {WinnerCard},
  computed: {
    ...mapGetters([
        'WINNERS',
        'TOURNAMENT'
    ])
  }
}
</script>

<style scoped>

</style>